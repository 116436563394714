import $ from "jquery";
import {BaseHandler} from "./BaseHandler";
import {BatchesTypes, getDefaultRequestOptions} from "../Api/Types";

type upload_t = "worked" | "printable" | "payments";

export class LoadDataHandler extends BaseHandler {
    private importing = false;


    private check_month(val: string): [number, number] {
        const [year, month] = val.split("-");

        const intYear = parseInt(year, 10);
        const intMonth = parseInt(month, 10);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        if (Number.isNaN(intYear) || Number.isNaN(intMonth))
            return null;
        if (intYear < 2015)
            return null;
        if (intYear > currentYear)
            return null;
        if (intYear === currentYear && intMonth > currentMonth)
            return null;

        return [intYear, intMonth];
    }

    private check_all() {
        for (const _container of document.querySelectorAll(".batch-container")) {
            const container = $(_container);

            try {
                const file = container.find<HTMLInputElement>('[name="file"]');
                if (!file[0].files.length) {
                    container.attr("data-valid", "false");
                    continue;
                }

                const name_e = container.find('[name="name"]');
                const name = name_e.val().toString().trim();
                if (name === "")
                    name_e.val(file[0].files[0].name.split(".")[0]);

                const month_e = container.find('[name="month"]');
                if (month_e.length) {
                    const parsed = this.check_month(month_e.val().toString().trim());
                    if (parsed === null) {
                        container.attr("data-valid", "false");
                        continue;
                    }

                    month_e.attr("data-parsed", JSON.stringify(parsed));
                }

                container.attr("data-valid", "true");
            } finally {
                const ok = container.attr("data-valid") === "true" && !this.importing;
                const button = container.find('[data-action="upload"]');

                button.prop("disabled", !ok);
            }
        }
    }

    private handle_upload(container: JQuery) {
        const type = <upload_t>container.attr("data-contains");

        const name = container.find('[name="name"]').val().toString().trim();
        const month_e = container.find('[name="month"]');
        const month = month_e.length ? JSON.parse(month_e.attr("data-parsed")) : null;

        const file = container.find<HTMLInputElement>('[name="file"]')[0].files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64 = (<string>reader.result).split(',')[1];

            const options = getDefaultRequestOptions({show_loading: false});
            const x = (
                type === "printable" ?
                    this.client.upload_batch(base64, name, BatchesTypes.PRINTABLE, options) :
                    type === "worked" ?
                        this.client.upload_batch(base64, name, BatchesTypes.WORKED, options) :
                        this.client.upload_manual_payments(base64, name, <number>month[1], <number>month[0], options)
            );

            this.update_loop(x, type);
            x.fail(() => {
                this.importing = false;
                this.check_all();
            });

            container.find("input").val("");
            this.check_all();
        }

        reader.readAsDataURL(file);
    }

    private update_loop(parent_task?: JQuery.Deferred<any>, source?: upload_t) {
        const end_progress = (status: JQuery) => {
            this.importing = false;
            this.check_all();
            const template = this.get_template_jquery("upload-status-completed");

            status.empty().append(template);

            template.addClass('fade-in').addClass('fade-out');

            setTimeout(() => template.removeClass('fade-out').addClass('fade-in'), 10);

            setTimeout(() => template.removeClass('fade-in').addClass('fade-out'), 4000);
            setTimeout(() => template.hide(), 5000);
        }

        const options = getDefaultRequestOptions({show_loading: false});

        if (parent_task !== void 0) {
            let exit = false;

            const interval = this.api_call_loop(() => {
                return this.client.check_upload(options).ew_done(x => {
                    if (x.uploading) {
                        exit = true;
                        clearInterval(interval);
                        this.update_loop();
                    }
                });
            }, 750);

            const status = $(`[data-contains="${source}"]`).find('[data-content-type="status"]')
            parent_task.done(() => {
                if (!exit) {
                    clearInterval(interval);
                    end_progress(status)
                }
            });
            parent_task.fail(() => {
                if (!exit)
                    clearInterval(interval);
            });
            return;
        }

        const template = this.get_template_jquery("upload-status-template");
        const inserted = template.find('[data-content-type="inserted"]');
        const total = template.find('[data-content-type="total"]');

        let appended = false;
        this.importing = true;
        this.check_all();

        const interval = this.api_call_loop(() => {
            return this.client.get_upload_progress(options).ew_done(p => {
                const status = $(`[data-contains="${p.type}"]`).find('[data-content-type="status"]')
                inserted.text(p.inserted);
                total.text(p.total);

                if (p.total === p.inserted) {
                    clearInterval(interval);
                    end_progress(status);
                    return;
                }

                if (!appended) {
                    status.empty().append(template);
                    appended = true;
                }
            });
        }, 750);
    }


    public override handle() {
        super.handle();

        $(".batch-container input").on("change", () => this.check_all());
        $('.batch-container [data-action="upload"]').on("click", e => {
            const container = $(e.currentTarget).closest(".batch-container");
            this.handle_upload(container);
        });

        this.client.check_upload().ew_done(x => {
            if (x.uploading)
                this.update_loop();
            else
                this.check_all();
        });
    }
}
