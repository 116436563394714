import $ from "jquery"
import {BaseHandler} from "../Handlers/BaseHandler";

export type InjectedPopup<T extends Popup> = {
    popup: T,
    deferred: JQuery.Deferred<any>
}

/**
 * Example for error:
 *         const t = Popup.DefaultErrorInject("<b>With htmllllllll</b>", "Titleeeeeeeeeeee", true);
 *         const t = Popup.DefaultErrorInject("Without htmllllllll", "Titleeeeeeeeeeee", false);
 *         t.popup.SetShowActions(true);
 *         const set_animation = (d: JQuery.Deferred<any>) => {
 *             d.fail(() => t.popup.ShowError("WRONGGGGGGGGG"));
 *             d.done(() => {alert(); t.popup.Remove()});
 *
 *             d.always((x: JQuery.Deferred<any>) => set_animation(x))
 *         }
 *
 *         set_animation(t.deferred);
 */

export class Popup {
    protected controls: Record<string, JQuery> = {};
    protected element: JQuery;


    public constructor(private title: string = null,
                       private desc: string | JQuery = null,
                       private html_desc: boolean = false,
                       private show_close_cross: boolean = false) {
    }

    public static DirectInject(desc: string | JQuery = null,
                               title: string = null,
                               html_desc: boolean = false,
                               show_close_cross: boolean = false) {
        const self = new this(title, desc, html_desc, show_close_cross);
        const deferred = self.Inject();
        return <InjectedPopup<typeof self>>{popup: self, deferred: deferred};
    }

    public static DefaultErrorInject(desc: string = "Una richiesta è fallita",
                                     title: string = "Errore",
                                     html_error: boolean = false) {
        const self = new this(title, desc, html_error, true);
        self.SetShowCloseCross();
        const deferred = self.Inject();
        self.SetShowActions(false);

        return <InjectedPopup<typeof self>>{popup: self, deferred: deferred};
    }

    public SetShowCloseCross(flag: boolean = true): this {
        this.show_close_cross = flag;

        if (this.element !== void 0) {
            const cross = this.element.find(".div-close");
            if (flag)
                cross.show();
            else
                cross.hide();
        }
        return this;
    }

    public GetShowCloseCross(): boolean {
        return this.show_close_cross;
    }

    public SetTitle(title: string): this {
        this.title = title;
        return this;
    }

    public GetTitle(): string {
        return this.title;
    }

    public SetDesc(desc: string | JQuery): this {
        this.desc = desc;
        return this;
    }

    public GetDesc(): string | JQuery {
        return this.desc;
    }

    public GetJQuery(): undefined | JQuery {
        return this.element;
    }

    public AddControl(name: string, element: JQuery): this {
        this.controls[name] = element;
        return this;
    }

    public RemoveControl(name: string): this {
        delete this.controls[name];
        return this;
    }

    protected BeforeInjecting() {

    }

    protected AfterInjecting() {

    }

    public Inject(): JQuery.Deferred<any> {
        const tpl = this.GetWrapper();
        tpl.find(".popup-contents").append(this.GetTemplate());
        tpl.find(".popup-title").text(this.title);

        if (this.desc !== null) {
            if (this.html_desc)
                tpl.find(".popup-desc").empty().append(this.desc);
            else
                tpl.find(".popup-desc").text(typeof this.desc === "string" ? this.desc : this.desc.text());
        }

        for (const val in this.controls)
            tpl.find(".popup-actions-wp").append(this.controls[val]);

        let deferred = $.Deferred();

        // Handle "OK" button click
        tpl.on("click", ".popup-act-ok", () => {
            const tmp = $.Deferred();
            deferred.resolve(tmp);
            deferred = tmp;
        });

        // Handle "Cancel" button click
        tpl.on("click", ".popup-act-cancel", () => {
            const tmp = $.Deferred();
            deferred.reject(tmp);
            deferred = tmp;
        });

        this.element = tpl;
        this.BeforeInjecting();

        const append_to = $(".popup-wp").last();
        if (append_to.length === 0)
            $(document.body).prepend(tpl);
        else
            tpl.insertAfter(append_to);

        this.SetShowCloseCross(this.GetShowCloseCross());
        this.element.find(".div-close").on("click", () => this.Remove());

        // Programmatically set focus to the input element safely
        const inputElement = this.element.find('#new-batch-name-input').get(0) as HTMLInputElement;
        if (inputElement) {
            inputElement.focus();
        }

        // Close the popup when clicking outside of it
        const closePopup = () => {
            this.Remove()
        };

        // Prevent clicks inside the popup from closing it
        tpl.on("click", (e) => {
            e.stopPropagation(); // Stops the click event from bubbling up to the document
        });

        // Detect click outside the popup
        $(document).on("mousedown.popup", (e) => {
            const target = e.target;

            // Check if the clicked element is outside the popup-data-container
            if (target instanceof HTMLElement && !$(target).closest('.popup-data-container').length) {
                closePopup();
            }
        });

        // Detect "Esc" key press
        $(document).on("keydown.popup", (e) => {
            const activeElement = document.activeElement;
            if (e.key === "Escape") {
                closePopup();
            } else if (e.key === "Enter") {
                if (activeElement.tagName === "TEXTAREA") return
                this.element.find('.popup-act-ok').trigger('click');
            } else if (e.key === " ") {
                // Check if the active element is an input of type text
                if (activeElement.tagName !== "BUTTON") return;
            } else return;

            e.preventDefault(); // This prevents default behavior for other cases
        });

        this.AfterInjecting();

        // Clean up event listeners when popup is closed
        const cleanupEvents = () => {
            $(document).off("keydown.popup");
            $(document).off("mousedown.popup");
        };

        // Override Remove method to include cleanup and return a boolean
        const originalRemove = this.Remove;
        this.Remove = (): boolean => {
            cleanupEvents();
            originalRemove.call(this);
            return true;  // Ensures the method returns a boolean
        };

        return deferred;
    }


    public SetShowActions(flag: boolean = true): boolean {
        if (this.element === void 0)
            return false;

        const t = this.element.find(".popup-main-actions-wp");
        if (flag)
            t.show();
        else
            t.hide();
        return true;
    }

    public Remove(): boolean {

        if (this.element === void 0)
            return false;

        $(document).off("mousedown.popup").off("keydown.popup")

        this.element.empty().detach();
        return true;
    }

    public ShowError(error: string | JQuery, html_error: boolean = false): boolean {
        if (this.element === void 0)
            return false;

        this.element.find(".popup-error-wp").show();
        const tmp = this.element.find(".popup-error-message");

        if (html_error)
            tmp.empty().append(error);
        else
            tmp.text(typeof error === "string" ? error : error.text());
    }

    protected GetWrapper(): JQuery {
        return BaseHandler.INSTANCE.get_template_jquery("generic-popup-wrapper");
    }

    protected GetTemplate(): JQuery {
        return $(`<div><p class="popup-desc"></p></div><div class="popup-actions-wp"></div>`);
    }

    public AlignContent() {
        const w = this.element.find(".popup-data-container");
        const total = $(document).height();
        const to_margin = (total - w.height()) / 2;
        const before = 110;
        const vh = to_margin / total * 100 + before;

        w.css({"margin-top": "-" + before + "vh"});

        w.animate({
                "margin-top": "+=" + vh + "vh"
            },
            400)
    }
}
