import $ from "jquery";
import {BeginReattach, FinalReport, PrintBatch, ProgressPhase, Update} from "../Api/Types";
import {Popup} from "../Popups/Popup";
import {UpdaterBaseHandler, UpdaterContainers} from "./UpdaterBaseHandler";
import {NavigableTable} from "./NavigableTableHandler";


export class ClosePrintingBatchesHandler extends UpdaterBaseHandler {

    private batches_table: NavigableTable<PrintBatch>

    protected change_step(no: number): void {
        $(".tabs > .tab").removeClass("active")[no - 1].classList.add("active");
        $(".tab-content").hide()[no - 1].style.display = "block";

        const reset_all = $("#reset-all");
        [2, 3, 4].includes(no) ? reset_all.show() : reset_all.hide();

        const next = $("#next-button");
        [2, 3].includes(no) ? next.show() : next.hide();

        const prev = $("#back-button");
        [].includes(no) ? prev.show() : prev.hide();

        $("#start-step").hide()
    }


    protected patch_next_button(disabled: boolean) {
        const button = $<HTMLButtonElement>("#next-button");
        button.prop("disabled", disabled)
        return button;
    }

    private build_batch_row(e: PrintBatch) {
        const next_callback = () => {
            this.client.begin_shipping_slip().ew_done(
                all_updates => {
                    this.client.create_shipping_slip();
                    this.create_shipping_slip(undefined, all_updates);
                }
            )
        };

        const row = this.get_template_jquery<HTMLTableRowElement>("close-batch-row-template");
        row.find('[data-when="closed"]').css("display", e.pending ? "none" : "block");
        row.find('[data-when="unclosed"]').css("display", !e.pending ? "none" : "block");

        row.find('[data-content-type="name"]').text(e.name);
        row.find('[data-content-type="creation-date"]').text(this.format_date(e.creation_date));
        row.find('[data-content-type="shipping-date"]').text(this.format_date(e.shipping_date));
        row.find('[data-content-type="items"]').text(e.items);

        row.find('[data-action="next"]').on("click", () => next_callback());
        row.find('[data-action="info"]').on("click", () =>
            this.client.get_final_report(e.print_batch_id).ew_done(r => this.show_report(r, false))
        );
        row.find('[data-action="change-name"]').on("click", () => this.change_batch_name(e.print_batch_id, row));

        return row;
    }

    private change_batch_name(print_batch_id: number, row: JQuery) {
        const title = this.get_template_text("change-batch-name-title-template");
        const actual_name = row.find('[data-content-type="name"]').text();
        const x = Popup.DirectInject(this.get_template_jquery("change-batch-name-template"), title, true);
        x.popup.SetShowCloseCross()
        x.popup.SetShowActions()
        const inp = $("#new-batch-name-input");
        inp.val(actual_name);

        const rename = (name: string) => {
            this.client.update_print_batch_name(print_batch_id, name);
            row.find('[data-content-type="name"]').text(name);
            x.popup.Remove();
        }

        const on_confirm = (deferred: JQuery.Deferred<any>) => {
            deferred.done(d => {
                const inp_val = inp.val().toString().trim().replace(/\s+/g, " ");
                if (inp_val === "")
                    x.popup.ShowError(this.get_template_jquery("change-batch-name-empty-template"))
                else if (inp_val.length < 12)
                    x.popup.ShowError(this.get_template_jquery("change-batch-name-too-short-template"))
                else if (inp_val.length > 128)
                    x.popup.ShowError(this.get_template_jquery("change-batch-name-too-long-template"))
                else
                    rename(inp_val);
                on_confirm(d);
            });
            deferred.fail(() => x.popup.Remove())
        }
        on_confirm(x.deferred);
    }

    private list_print_batches(drop_cache: boolean = false) {
        this.change_step(1);

        if (drop_cache) {
            this.batches_table.drop_greatest();
            this.batches_table.page = this.batches_table.pages ?? 0;
        } else
            this.batches_table.update();
    }

    protected get_containers(step: number) {
        return {
            progress_container: $(`#batch-container-step-${step}`)
        } as UpdaterContainers;
    }

    private create_shipping_slip(reattach?: BeginReattach, updates?: Update[]) {
        const next_button = this.handleCase(2,
            () => this.client.create_shipping_slip(),
            reattach,
            {init: updates});

        next_button.off("click").on("click", () => {
            this.client.begin_send_to_printer().ew_done(updates => {
                this.client.send_to_printer();
                this.send_to_printer(undefined, updates);
            })
        })
    }

    private send_to_printer(reattach?: BeginReattach, updates?: Update[]) {
        const formatter = new Intl.NumberFormat(document.documentElement.lang, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        const format_size = (kb: number) => formatter.format(kb / Math.pow(1024, 1));

        const next_button = this.handleCase(3,
            () => this.client.send_to_printer(),
            reattach,
            {
                init: updates,
                formatter: (processed_no: number, total_no: number) => format_size(processed_no) + "/" + (total_no < 0 ? "?" : format_size(total_no)) + "MB",
                merged_phase: true
            });
        next_button.off("click").on("click", () => {
            this.client.close_process().ew_done(report => this.show_report(report, true))
        });
    }

    private show_report(report: FinalReport, from_print_process: boolean) {
        this.change_step(4);

        if (!report.can_reset)
            $("#reset-all").hide();

        const container = $('#results-container');

        const x = {
            style: "currency",
            currency: "EUR",
        }
        //@ts-ignore
        const amount = new Intl.NumberFormat(document.documentElement.lang, x).format(Number(report.total_import.toFixed(2)));
        container.find('[data-content-type="results-total-letters"]').text(report.verbal_no);
        container.find('[data-content-type="results-import-sum"]').text(amount);
        container.find('[data-content-type="results-total-items"]').text(report.items);

        container.find('[data-content-type="results-generated-pdf"]').text(report.generated_pdf_no);

        $("#download-pdf").off("click").on("click", () => this.download(report.pdf_download_url));
        $("#download-shipping-list").off("click").on("click", () => this.download(report.distinct_download_url));

        if (from_print_process)
            $("#back-button").show().off("click").one("click", () => this.list_print_batches(true))
        else
            $("#back-button").show().off("click").one("click", () => this.change_step(1))

    }

    private draw_page(reattach: BeginReattach) {
        if (reattach.effective_state.phase === ProgressPhase.SHIPPING_SLIP) {
            this.create_shipping_slip(reattach)
        } else if (reattach.effective_state.phase === ProgressPhase.SEND_TO_PRINTER)
            this.send_to_printer(reattach)
        else
            this.list_print_batches()
    }

    private reattach() {
        this.client.begin_reattach().ew_done(x => this.draw_page(x))
    }

    public override handle(): void {
        super.handle();
        const tableWrapper = $('#batch-selector-table-wrapper');
        this.batches_table = new NavigableTable(tableWrapper,
            e => this.build_batch_row(e),
            (page_index, items_per_page) =>
                this.client.get_print_batches(items_per_page, page_index)
        );
        this.reattach();
        $('#reset-all').on("click", () => this.client.reset_all().ew_done(() => window.location.reload()));
    }
}
