import $ from 'jquery';
import {BaseHandler} from "./BaseHandler";
import {NavigableTable} from "./NavigableTableHandler";
import {Mailer} from "../Api/Types";
import {Popup} from "../Popups/Popup";

export class ManageCorrespondentsHandler extends BaseHandler {
    private navigableTable: NavigableTable<Mailer>
    public readonly nation_map: JQuery.Deferred<Record<number, string>> = $.Deferred();

    private buildRow(e: Mailer, data_extended: string = "false") {
        const row = this.get_template_jquery<HTMLTableRowElement>("mailer-row-template");

        this.nation_map.done(map => {
            const notes = row.find('[data-content-type="notes"]');
            row.find('[data-content-type="name"]').text(e.name);
            row.find('[data-content-type="email"]').text(e.email);
            row.find('[data-content-type="ref_nation_id"]').text(map[e.ref_nation_id]);
            notes.text(e.notes);
            row.find('[data-action="modify-row"]').on("click", () => this.modify_row(e, row));
            row.find('[data-content-type="is_active"]')
            row.attr("data-disabled", !e.enabled ? "true" : "false");

            notes.attr("data-extended", data_extended)

            notes.on("dblclick", (e: JQuery.DoubleClickEvent) => {
                e.preventDefault();
                const selection = window.getSelection();
                if (selection) {
                    selection.removeAllRanges(); // Clear any selected text
                }

                notes.attr("data-extended", notes.attr("data-extended") === "true" ? "false" : "true")
            })
        })

        return row;
    }

    private populateDatalist() {
        this.client.get_nation_mapping().done(nations => {
            const rt: Record<number, string> = {};

            for (const nation of nations)
                rt[nation.nation_id] = `${nation.name} (${nation.code})`;

            this.nation_map.resolve(rt);
        });
    }

    private modify_row(correspondent: Mailer = null, row: JQuery = null) {
        const modify = correspondent !== null;

        const mail_regex = /[a-zA-Z0-9.\-_]{3,}@[a-zA-Z]{3,}.[a-zA-Z]{2,}/;

        const title = this.get_template_text(modify ? "change-correspondent-row-title-template" : "add-correspondent-row-title-template");
        const actual_name = row.find('[data-content-type="name"]').text();
        const actual_email = row.find('[data-content-type="email"]').text();
        const actual_ref_nation_id = row.find('[data-content-type="ref_nation_id"]').text();
        const actual_notes = row.find('[data-content-type="notes"]').text();
        const actual_is_active = row.attr('data-disabled') === "false";
        const x = Popup.DirectInject(this.get_template_jquery("change-batch-row-template"), title, true);
        x.popup.SetShowCloseCross();
        x.popup.SetShowActions();

        const name_inp = $("#new-correspondent-name-input");
        name_inp.val(actual_name);

        const mail_inp = $("#new-correspondent-email-input");
        mail_inp.val(actual_email);

        const nation_inp = $<HTMLSelectElement>("#new-correspondent-nation-input");

        const data_list = $('<datalist>');
        this.nation_map.done(map => {
            for (const i in map) {
                const option = $("<option>").val(i).text(map[i]);
                nation_inp.append(option);
            }
            data_list.val(actual_ref_nation_id);
        })

        nation_inp.append(data_list)

        const note_inp = $("#new-correspondent-note-input");
        note_inp.val(actual_notes);

        const is_active_inp = $("#is_active");
        is_active_inp.prop('checked', !actual_is_active);

        const rename = (name: string, email: string, nation: number, note: string, disable: boolean) => {
            if (modify) {
                this.client.update_correspondent(correspondent.correspondent_id, name, email, nation, disable, note).done(() => {
                    correspondent.name = name;
                    correspondent.email = email;
                    correspondent.notes = note;
                    correspondent.ref_nation_id = nation;
                    correspondent.enabled = disable;
                    console.log(row.find('[data-content-type="notes"]').attr("data-extended"));
                    row.replaceWith(this.buildRow(correspondent, row.find('[data-content-type="notes"]').attr("data-extended")))
                    x.popup.Remove();
                })
            } else {
                this.client.create_correspondent(name, email, nation, !disable, note).done(() => {
                    x.popup.Remove();
                    this.navigableTable.drop_greatest()
                    this.navigableTable.page = 1;
                });
            }
        }

        // Validator functions
        const isValidName = (name: string): string | null => {
            if (name === "")
                return this.get_template_text("change-correspondent-name-empty-template");
            if (name.length < 4)
                return this.get_template_text("change-correspondent-name-too-short-template");
            if (name.length > 128)
                return this.get_template_text("change-correspondent-name-too-long-template");
            return null;
        };

        const isValidNotes = (notes: string): string | null => {
            if (notes.length > 512)
                return this.get_template_text("change-correspondent-notes-too-long-template");
        }

        const isValidMail = (mail: string): string | null => {
            if (mail === "")
                return this.get_template_text("change-correspondent-mail-empty-template");
            if (mail.length > 128)
                return this.get_template_text("change-correspondent-mail-too-long-template");
            if (!mail_regex.test(mail))
                return this.get_template_text("change-correspondent-mail-invalid");
            return null;
        };

        const isValidNation = (nation: number): string | null => {
            if (!nation)
                return this.get_template_text("change-correspondent-nation-empty-template");
            return null;
        }

        const on_confirm = (deferred: JQuery.Deferred<any>) => {
            deferred.done(d => {
                const name_val = name_inp.val().toString().trim().replace(/\s+/g, " ");
                const mail_val = mail_inp.val().toString().trim().replace(/\s+/g, " ");
                const nation_val = Number(nation_inp.val());
                const note_val = note_inp.val().toString().trim().replace(/\s+/g, " ");
                const is_active: boolean = is_active_inp.prop('checked');

                // Collect all errors
                const errors: string[] = [];

                const nameError = isValidName(name_val);
                if (nameError) errors.push(nameError);

                const mailError = isValidMail(mail_val);
                if (mailError) errors.push(mailError);

                const nationError = isValidNation(nation_val);
                if (nationError) errors.push(nationError);

                const notesError = isValidNotes(note_val);
                if (notesError) errors.push(notesError);

                // If there are errors, display them
                if (errors.length > 0) {
                    const error_message = "<div>" + errors.join("<div></div>") + "</div>"
                    x.popup.ShowError(error_message, true);
                } else {
                    // No errors, proceed with renaming
                    rename(name_val, mail_val, nation_val, note_val, !is_active);
                }

                on_confirm(d); // No need for recursion here, remove this line
            });

            deferred.fail(() => x.popup.Remove());
        };

        on_confirm(x.deferred);
        if (modify) this.navigableTable.drop_current();
        else this.navigableTable.drop_cache()
    }

    private get_mails(dropped: boolean = false) {
        if (dropped) this.navigableTable.drop_from_current();
        else this.navigableTable.drop_cache();
        this.navigableTable.update();
    }


    public override handle() {
        super.handle();

        $('[data-action="add-correspondents"]').on('click',
            () => this.modify_row(null, $()));

        this.populateDatalist();
        const tableWrapper = $('#table-wrapper-manage-mailer');
        this.navigableTable = new NavigableTable(tableWrapper, e => {
            return this.buildRow(e)
        }, (page_index, items_per_page) => {
            return this.client.get_correspondents(items_per_page, page_index);
        });
        this.get_mails();
    }
}