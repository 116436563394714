import $ from "jquery";
import {SmartpriseApiClient} from "../Api/Client";
import {getDefaultRequestOptions, RetryAfterErrorData} from "../Api/Types";
import {BaseHandler} from "./BaseHandler";

export class LoginHandler extends BaseHandler {
    private last_error_box: JQuery = null;

    protected AddErrorBox(content: JQuery | string): void {
        this.RemoveErrorBox();

        this.last_error_box = this.get_template_jquery("error-box");
        const text = this.last_error_box.find(".error-content-wp");
        if (typeof content === "string")
            text.text(content);
        else
            text.append(content);

        this.last_error_box.insertAfter("#society-name");
    }

    protected RemoveErrorBox() {
        if (this.last_error_box !== null)
            this.last_error_box.detach();

        this.last_error_box = null;
    }

    private SetLoginHook(): void {
        const email = $("#email");
        const password = $("#password");
        const login_btn = $("#login");

        login_btn.prop("disabled", false);

        login_btn.on("click", () => {
            if (password.val() === "" || email.val() === "") {
                this.AddErrorBox("Password e email sono campi richiesti");
                return;
            }

            const options = getDefaultRequestOptions();
            options.default_eh = false;
            options.fail_handle_session_handle = false;

            this.client.Login(email.val() as string,
                password.val() as string,
                options).fail(
                e => {
                    let msg: string;

                    if (typeof e === "string")
                        msg = e ?? "Errore sconosciuto";
                    else if (e.error.code === 16) {
                        const retry_after_e = new RetryAfterErrorData(e.error.data);
                        localStorage.setItem("date_wait", retry_after_e.retry_after.toISOString());
                        this.HandleRetryAfter(retry_after_e.retry_after.toISOString());
                        return;
                    } else
                        msg = e.error.code in SmartpriseApiClient.ErrorMap ? SmartpriseApiClient.ErrorMap[e.error.code] : e.error.message;


                    this.AddErrorBox(msg)
                }
            ).ew_done(() =>
                document.location.search = this.url_params.toString()
            )
        });
    }

    private HandleRetryAfter(date_wait: string): void {
        let retry_after = this.get_template_jquery("retry-after-msg");
        this.AddErrorBox(retry_after);
        retry_after = retry_after.find(".retry-after-container");

        let parsed = Date.parse(date_wait);
        if (isNaN(parsed) || parsed < Date.now()) {
            localStorage.removeItem("date_wait");
            this.SetLoginHook()
            return;
        }

        const login = $("#login");
        login.prop("disabled", true);

        const get_date = (u: number) => {
            u -= Date.now();
            if (u < 0) u = 0;
            const dt = new Date(u);
            const hours = dt.getUTCHours().toString().padStart(2, '0');
            const minutes = dt.getUTCMinutes().toString().padStart(2, '0');
            const seconds = dt.getUTCSeconds().toString().padStart(2, '0');

            return `${hours}:${minutes}:${seconds}`;
        }

        const interval = setInterval(() => {
            retry_after.text(get_date(parsed));
        }, 800);

        setTimeout(() => {
            login.prop("disabled", true);
            localStorage.removeItem("date_wait");
            clearInterval(interval);
            this.RemoveErrorBox();
            this.SetLoginHook()
        }, parsed - Date.now())

        retry_after.text(get_date(parsed));
    }

    public override handle() {
        super.handle();

        $(document).on("keypress", e => {
            if (e.key === "Enter") {
                $('#login').trigger('click');
            }
        })

        const date_wait = localStorage.getItem("date_wait");
        if (date_wait === null) {
            this.SetLoginHook()
            return;
        }

        this.HandleRetryAfter(date_wait);
    }
}